<template>
  <MediaQueryProvider :queries="$options.queries">
    <div id="app" :class="classNames()">
      <router-view />
    </div>
  </MediaQueryProvider>
</template>
<script>
import { get } from "vuex-pathify";
import { MediaQueryProvider } from "vue-component-media-queries";

const queries = {
  mobile: "(max-width: 760px)",
};

export default {
  name: "App",
  queries,
  data() {
    return {};
  },
  components: {
    MediaQueryProvider,
  },
  computed: {
    app: get("app"),
    showIntro: get("showIntro"),
    activeSubpage: get("activeSubpage"),
    mobileAppView: get("mobileAppView"),
    /*app() {
      if (this.$store.state.isMobile) {
        return this.$store.state.app;
      }
      return false;
    },*/
  },
  methods: {
    classNames() {
      if (this.activeSubpage) {
        return 'subpage-active';
      } else if (this.app || this.showIntro) {
        return 'app-skafferiet';
      } else {
        return 'app-main';
      }
    }
  },
  watch: {
    "$store.state.noScroll": function () {
      if (this.$store.state.noScroll) {
        document.documentElement.classList.add("no-scroll");
      } else {
        document.documentElement.classList.remove("no-scroll");
      }
    },
  },
  mounted() {
    // Add parameter to router
    if (this.$route.query.app) {
      this.$store.commit('toggleMobileAppView', true)
    } else {
      this.$store.commit('toggleMobileAppView', false)
    }

    // Session on site and local on Mobile App site
    if (this.mobileAppView) {
      if (localStorage.showIntroStorage !== 'false') {
        localStorage.setItem("showIntroStorage", true);
        this.$store.commit('toggleShowIntro', true);
      }
    } else {
      if (sessionStorage.showIntroStorage !== 'false') {
        sessionStorage.setItem("showIntroStorage", true);
        this.$store.commit('toggleShowIntro', true);
      }
    }
  },
  destroyed() {
    this.$store.commit('toggleShowIntro', false);
  },
};
</script>
<style lang="scss">
@import "assets/css/fonts.scss";
@import "assets/css/colors.scss";
@import "assets/css/mixins.scss";

html,
body {
  height: 100%;
}

#app {
  @include for-tablet-landscape-up() {
    width: calc(100% - 400px);
    margin-left: 400px;
  }

  font-family: "Open Sans",
  Helvetica,
  Arial,
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $brown;
  background-color: #fff;
  min-height: 100%;

  &.app-skafferiet {
    background-color: #e5e5e5;
  }
}

h2,
h3,
h4,
h5,
h6,
.co2-title,
#skafferiet,
.button,
.menu__navigation {
  font-family: "WWF", Helvetica, Arial, sans-serif;
}

// Font size
h2 {
  font-size: 2.2rem !important;
  line-height: 1;
  margin: 3rem 0 !important;
  text-transform: uppercase;
}

p {
  font-size: 16px;

  @include for-tablet-portrait-up() {
    font-size: 18px;
  }
}

.no-scroll {
  overflow: hidden !important;
}

.click-event {
  cursor: pointer;
}

.close,
.modal-close,
.open-menu {
  &:hover img {
    opacity: 0.8;
    transform: scale(1.1);
  }
}
</style>
