<template>
    <div>
      <ul class="language">
        <li
          v-for="(lang, i) in langs"
          :key="`lang-${i}`"
          @click="switchLocale(lang)"
          :class="$i18n.locale === lang && 'active'"
        >
          {{lang && currentLang(lang)}}
        </li>
      </ul>
    </div>
  </template>
  <script>
  export default {
    name: 'SwitchLocale',
    data() {
      return { langs: ['sv', 'en'] }
    },
    methods: {
      currentLang(lang) {
        if (lang === 'sv') return 'Svenska'
          else return 'English'
      },
      switchLocale(locale) {
        if (this.$i18n.locale !== locale) {
          this.$i18n.locale = locale;
        }
      }
    },
  }
</script>
<style lang="scss">
@import "../assets/css/colors.scss";
@import "../assets/css/mixins.scss";
.language {
  display: inline-flex;
  justify-content: center;
  border: 2px solid $primary;
  border-radius: 100px;
  overflow: hidden;
  background-color: $primary;
  margin-top: 1em;

  li {
    font-family: "WWF", Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 25px;
    padding: 8px 20px;
    text-transform: uppercase;
    cursor: pointer;
  }
  .active {
    background-color: $white;
    border-radius: 100px;
  }
}

.intro {
  .language li {
    color: #666;
  }
  .language .active {
    color: $brown;
  }
}
</style>