<template>
  <div id="plate-wrapper" ref="plateWrapper">
    <canvas id="pixi" ref="plate"></canvas>
  </div>
</template>

<script>
import * as PIXI from "pixi.js";
import { gsap } from "gsap";
import { EasePack } from "gsap/EasePack";
import { PixiPlugin } from "gsap/PixiPlugin.js";
import * as lodash from "lodash";
import { get } from "vuex-pathify";

export default {
  components: {},
  props: {
    cubeData: Array,
    groceries: Array,
    showSliders: Boolean,
  },
  data() {
    return {
      app: new PIXI.Application(),
      graphics: new PIXI.Graphics(),
      container: new PIXI.Container(),
      sysInfo: { width: 0, height: 0 },
      counter: 0,
      activeCubes: new Array(),
      baseCubes: new Array(),
      bunny: new PIXI.Sprite(),
      plateSprite: null,
      centerX: 0,

      cubePositions: new Array(
        { x: 0, y: 240, zIndex: 3, position: 0 }, //mitten
        { x: -50, y: 220, zIndex: 1, position: 1 }, //uppe vänster
        { x: 50, y: 220, zIndex: 2, position: 2 }, //uppe höger
        { x: -70, y: 270, zIndex: 6, position: 3 }, //framför mitten vänster
        { x: 70, y: 270, zIndex: 7, position: 4 }, //framför mitten höger
        { x: 0, y: 275, zIndex: 8, position: 5 }, //längst fram
        { x: -100, y: 240, zIndex: 4, position: 6 }, //sidan vänster
        { x: 100, y: 240, zIndex: 5, position: 7 }, //****SISTA PÅ TALLRIKEN     sidan höger

        { x: 0, y: 240 - 40, zIndex: 3 + 7, position: 8 }, //mitten
        { x: -50, y: 220 - 40, zIndex: 1 + 7, position: 9 }, //uppe vänster
        { x: 50, y: 220 - 40, zIndex: 2 + 7, position: 10 }, //uppe höger
        { x: -70, y: 270 - 40, zIndex: 6 + 7, position: 11 }, //framför mitten vänster
        { x: 70, y: 270 - 40, zIndex: 7 + 7, position: 12 }, //framför mitten höger
        { x: 0, y: 275 - 40, zIndex: 8 + 7, position: 13 }, //längst fram
        { x: -100, y: 240 - 40, zIndex: 4 + 7, position: 14 }, //sidan vänster
        { x: 100, y: 240 - 40, zIndex: 5 + 7, position: 15 } //sidan höger
      ),
      cubeRatio: 256 / 238,
    };
  },
  computed: {
    whichApp: get("app"),
  },
  methods: {
    initPixi() {
      // console.log("initPixi");
      gsap.registerPlugin(PixiPlugin);
      gsap.registerPlugin(EasePack);
      PixiPlugin.registerPIXI(PIXI);

      //var renderer = PIXI.autoDetectRenderer(size[0], size[1], null);

      this.app = new PIXI.Application({
        //antialias: true,
        view: this.$refs.plate,
        backgroundColor: this.getBackgroundColor(),
        width: 460,
        height: 345,
        //backgroundAlpha: 0,
        //autoDensity: true,
        //resoluton: 1,
        //resizeTo: this.$refs.plateWrapper,
      });
      //create all cubes

      this.centerX = Math.min(window.innerWidth / 2, this.app.screen.width / 2);
      this.centerX = this.app.screen.width / 2;
      let centerX = this.centerX;
      let container = this.container;

      container.sortableChildren = true;
      container.sortDirty = true;

      this.plateSprite = new PIXI.Sprite.from("assets/plate.png");
      container.addChild(this.plateSprite);

      this.plateSprite.width = 375;
      this.plateSprite.height = 375 / (1000 / 345);
      this.plateSprite.anchor.set(0.5, 0.5);
      //console.log(this.app.screen);
      this.plateSprite.x = this.app.screen.width / 2;
      this.plateSprite.y = this.app.screen.height - 80;

      /*console.log("inner width", window.innerWidth);
      console.log("inner height", window.innerHeight);
      console.log("app width", this.app.screen.width);
      console.log("app height", this.app.screen.height);
      console.log("center X", centerX);*/

      //let cubeRatio = this.cubeRatio;
      this.app.stage.addChild(container);

      //   this.bunny = new PIXI.Sprite.from("bunny.png");
      //   this.app.stage.addChild(this.bunny);
      //   this.bunny.anchor.set(0.5, 0.5);
      //   this.bunny.x = this.app.screen.width / 2;
      //   this.bunny.y = 250;

      this.groceries.forEach(function (grocery) {
        //console.log(grocery.label, index);

        //const randomTexture = lodash.sample(cubeTextures);
        const cube = PIXI.Sprite.from("assets/cubes/" + grocery.cubeUrl);
        cube.anchor.set(0.5, 0.5);
        container.addChild(cube);
        cube.x = centerX;
        grocery.cubeX = 0;
        cube.y = grocery.cubeY = 100;
        cube.cubeAlpha = 0;
        grocery.cubeSprite = cube;
        grocery.cubeActive = false;
        cube.zIndex = 0;
      });
    },
    handleResizedWindow() {
      //console.log("RZ: window inner width", window.innerWidth);
      //console.log("RZ: app  width", this.app.screen.width);

      //this.centerX = Math.min(window.innerWidth / 2, this.app.screen.width / 2);
      this.centerX = this.app.screen.width / 2;
      //this.plateSprite.x = this.centerX;
      /*gsap.to(this.plateSprite, 0.5, {
        x: this.centerX,
        delay: 0.25,
        overwrite: "allOnStart",
      });*/

      this.plateSprite.x = this.app.screen.width / 2;
      this.plateSprite.y = this.app.screen.height - 80;

      this.editCubes();
    },
    editCubes() {
      //console.log("editcubes");

      lodash.shuffle(this.cubePositions);
      //this.cubePositions = lodash.shuffle(this.cubePositions);
      let cubePositions = this.cubePositions;

      let container = this.container;
      container.sortChildren();
      let cubeRatio = this.cubeRatio;
      let centerX = this.centerX;

      function checkForEmptySpaces(grocery) {
        if (grocery.position >= 8) {
          if (cubePositions.some((e) => e.position === grocery.position - 8)) {
            // console.log(grocery.position, "this position is avaliable");
            //put back old position in array
            cubePositions.push({
              x: grocery.cubeX,
              y: grocery.cubeY,
              zIndex: grocery.zIndex,
              position: grocery.position,
            });

            //steal new position from empty space below
            let belowPosition = grocery.position - 8;
            let index = cubePositions.findIndex(
              (e) => e.position === belowPosition
            );

            // console.log(index);

            grocery.cubeX = cubePositions[index].x;
            grocery.cubeY = cubePositions[index].y;
            grocery.zIndex = cubePositions[index].zIndex;
            grocery.position = cubePositions[index].position;
            cubePositions.splice(index, 1);

            //move this cube down.
          }
        }
      }
      this.groceries.forEach(function (grocery) {
        //let cube = activeCubes[index];

        if (grocery.sliderValue > 0) {
          if (grocery.cubeActive) {
            //cube exists
            checkForEmptySpaces(grocery);
          } else {
            //add cube
            grocery.cubeActive = true;
            grocery.cubeAlpha = 1;
            if (cubePositions.length > 0) {
              grocery.cubeX = cubePositions[0].x;
              grocery.cubeY = cubePositions[0].y;
              grocery.zIndex = cubePositions[0].zIndex;
              grocery.position = cubePositions[0].position;
              cubePositions.splice(0, 1);
            }

            //take position from array
          }
        } else {
          if (grocery.cubeActive) {
            //remove cubeActive
            grocery.cubeActive = false;
            //put back x and y into positions array
            cubePositions.unshift({
              x: grocery.cubeX,
              y: grocery.cubeY,
              zIndex: grocery.zIndex,
              position: grocery.position,
            });
            //set cubeX and cubeY to start position
            //grocery.cubeX = 0;
            grocery.cubeY = 180;
            grocery.cubeAlpha = 0;
          }
        }

        if (grocery.cubeActive) {
          //cube exists
          checkForEmptySpaces(grocery);
        }

        //update size
        grocery.cubeSize = Math.round(
          (grocery.sliderValue * grocery.conversionFactor) / 10
        );
        grocery.cubeSprite.zIndex = grocery.zIndex;
        //grocery.cubeSprite.cubeText.text = "grocery.zIndex";
        // console.log(grocery.cubeSprite);
        //console.log(grocery.zIndex);

        function ease(t, b, c, d) {
          return c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
        }
        //console.log("cubesize", grocery.cubeSize);
        grocery.cubeSize = ease(grocery.cubeSize, 10, 100, 70);
        //container.sortChildren();

        gsap.to(grocery.cubeSprite, 0.5, {
          height: grocery.cubeSize,
          width: grocery.cubeSize * cubeRatio,
          x: centerX + grocery.cubeX,
          y: grocery.cubeY,
          delay: 0.25,
          ease: "Strong.easeOut",
          overwrite: "allOnStart",
        });
        gsap.to(grocery.cubeSprite, 0.5, {
          pixi: { alpha: grocery.cubeAlpha },
        });
      });
      //console.log(cubePositions);
      //container.children.sort((itemA, itemB) => itemA.zIndex - itemB.zIndex);
      container.sortChildren();
      //console.log(cubePositions);
    },
    getBackgroundColor() {
      if (this.whichApp) {
        return "0xE5E5E5";
      } else {
        return "0xFFFFFF";
      }
    },
  },
  watch: {
    whichApp() {
      this.app.renderer.backgroundColor = this.getBackgroundColor();
    },
    //cubeData(newData, oldData) {
    cubeData() {
      // console.log("cubeData changed:");
      // this.initPixi();
      this.editCubes();
      this.app.resize();
    },
  },

  mounted() {
    this.initPixi();
    this.editCubes();
    window.addEventListener("resize", this.handleResizedWindow);
    // console.log("pixelratio", window.devicePixelRatio);
  },
};
</script>

<style lang="scss">
@import "../assets/css/mixins.scss";

.app-skafferiet #plate-wrapper #pixi {
  transform: scale(0.7);
  opacity: 1;
  @include for-small-height() {
    transform: scale(1) translateY(-80px);
    opacity: 0;
  }
  @include for-tablet-landscape-up() {
    transform: scale(1);
  }
}

#plate-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;

  #pixi {
    min-width: 460px;
    transition: all 250ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */

    background-size: purple;
    @include for-small-height() {
      transform: scale(1) translateY(-50px);
    }
  }
}
</style>
