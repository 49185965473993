<template>
  <div>
    <header>
      <router-link :to="{ path: '/' }">
        <img class="logo" :src="iconSource" />
      </router-link>
      <span class="open-menu click-event" v-on:click="isCardModalActive = true">
        <Icon name="menu" />
      </span>
    </header>

    <b-modal class="menu" v-model="isCardModalActive" :can-cancel="true">
      <nav class="menu__navigation">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          {{$t('home.navigationTitle')}}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/matkalkylatorn' }">
          {{$t('about.navigationTitle')}}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/make-a-difference' }">
          {{$t('difference.navigationTitle')}}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/sustainability' }">
          {{$t('hallbarhetsaspekter.navigationTitle')}}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/climate-budget' }">
          {{$t('klimatbudget.navigationTitle')}}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/questions' }">
          {{$t('fragor.navigationTitle')}}
        </b-navbar-item>
        <LanguageSwitch />
      </nav>
      <div class="menu__text">
        <p>
          {{$t('home.data')}} <a href="https://carboncloud.com/" target="_blank">CarbonCloud</a><br />
          {{$t('home.by')}} <a href="https://boid.se/" target="_blank">Boid</a>
        </p>
        <br />
        <p>{{$t('home.cc')}}</p>
      </div>
    </b-modal>
  </div>
</template>
<script>
import WWF_Logo from "@/assets/WWF_Logo.svg";
import Icon from "@/components/Icon.vue";
import LanguageSwitch from "./LanguageSwitch.vue";

export default {
  components: {
    Icon,
    LanguageSwitch
},
  data() {
    return {
      iconSource: WWF_Logo,
      isCardModalActive: false,
    };
  },
};
</script>
<style lang="scss">
@import "../assets/css/colors.scss";
@import "../assets/css/mixins.scss";
header {
  .logo {
    left: 0;
    position: absolute;
    width: 65px;
    z-index: 50;
    @include for-tablet-landscape-up(){
      left: 16px;
    }
  }

  .open-menu {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 30;
  }
}

.menu {
  justify-content: flex-start !important;
  padding-top: 6rem;

  &__navigation .navbar-item {
    font-size: 24px;
    color: $white;
    line-height: 25px;
    padding: 12px 35px;
    text-align: left;
    text-transform: uppercase;

    &:hover,
    &.router-link-exact-active {
      background-color: rgba(255, 255, 255, 0.2);
      color: $white;
    }
  }

  &__text {
    bottom: 30px;
    color: $white;
    font-size: 12px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 90%;

    p {
      font-size: 14px;
    }

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  .modal-content {
    max-height: 100%;
    height: 100%;
  }

  .modal-close {
    right: 15px !important;
    top: 15px !important;
  }
}

.modal-close {
  height: 38px !important;
  max-height: 38px !important;
  max-width: 38px !important;
  min-height: 38px !important;
  min-width: 38px !important;
  width: 38px !important;
}
</style>
