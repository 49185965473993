<template>
  <div id="thermometer">
    <div id="thermometer-body">
      <div
        id="thermometer-fill"
        v-bind:style="{ backgroundColor: thermoColor, height: thermoHeight }"
      ></div>
    </div>
    <div class="AveragePerson">
      <div class="line"></div>
      <div class="label"><span>{{averageSwede()}}</span> {{$t('meter.avarage')}}</div>
    </div>
    <div class="OnePlanetPlate">
      <div class="line"></div>
      <div class="label"><span>{{sustainable()}}</span> {{$t('meter.sustainable')}}</div>
    </div>

    <svg width="0" height="0">
      <defs>
        <clipPath id="myClip">
          <path
            d="M8.9,90.4c0,5.3,4.3,9.6,9.6,9.6s9.6-4.3,9.6-9.6l0-80.8c0-5.3-4.3-9.6-9.6-9.6S8.9,4.3,8.9,9.6L8.9,90.4z"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    kgco2: Number,
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    currentLang() {
      return this.$parent.$i18n.locale;
    },
    thermoHeight() {
      //0-3kg = 0-100px

      let heightPx = this.map_range(this.kgco2, 0, 3, 0, 100);
      heightPx = Math.min(heightPx, 100);

      return heightPx + "px";
    },
    thermoColor() {
      if (this.kgco2 == 0) {
        return "transparent";
      }
      if (this.kgco2 < 0.59) {
        return "#8CC63F"; //green
      }
      if (this.kgco2 < 1.5) {
        //fade green to yellow
        //let ratio = this.map_range(this.kgco2, 0.5, 1.5, 0, 1);
        //return this.$chroma.mix("#8CC63F", "#F5D200", ratio);
        return "#F5D200";
        //return "#" + this.calcFadeColor("8CC63F", "F5D200", ratio);
      }

      if (this.kgco2 < 3) {
        //fade yellow to orange
        let ratio = this.map_range(this.kgco2, 1, 3, 0, 1);
        return this.$chroma.mix("#F5D200", "#FF862E", ratio);
      }

      if (this.kgco2 < 4.5) {
        //fade orange to red
        let ratio = this.map_range(this.kgco2, 3, 4.5, 0, 1);
        return this.$chroma.mix("#FF862E", "DE3900", ratio);
      }

      if (this.kgco2 < 7) {
        //fade red to dark red
        let ratio = this.map_range(this.kgco2, 4.5, 7, 0, 1);
        return this.$chroma.mix("DE3900", "#A02B02", ratio);
      }

      if (this.kgco2 < 10) {
        //fade dark red to brown
        let ratio = this.map_range(this.kgco2, 7, 10, 0, 1);
        return this.$chroma.mix("A02B02", "#652109", ratio);
      }

      if (this.kgco2 < 14) {
        //fade brown to dark brown
        let ratio = this.map_range(this.kgco2, 10, 14, 0, 1);
        return this.$chroma.mix("652109", "#3E1405", ratio);
      }

      if (this.kgco2 < 20) {
        //fade dark brown to black
        let ratio = this.map_range(this.kgco2, 14, 20, 0, 1);
        return this.$chroma.mix("3E1405", "#333333", ratio);
      }

      //   orange

      //   red

      //   dark red

      //   brown
      //   black
      //const colors = new Array("green", "yellow", "orange", "red");
      return "#333333";
    },
  },

  methods: {
    map_range(value, low1, high1, low2, high2) {
      return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
    },
    averageSwede() {
      if (this.currentLang === 'sv') return '1,8';
        else return '1.8';
    },
    sustainable() {
      if (this.currentLang === 'sv') return '0,5';
        else return '0.5';
    }
  },
};
</script>

<style lang="scss">
#thermometer {
  height: 100px;
  position: relative;
}

#thermometer-body {
  width: 37px;
  height: 100px;
  background-color: #ddd;
  clip-path: url(#myClip);
  position: relative;
  background-image: url("../assets/gfx/termometer.png");
  background-size: cover;
  transform: scale(1.2);
  #thermometer-fill {
    transition: all 250ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30px;
    width: 100%;
    background-color: green;
  }

  img {
    clip-path: url(#myClip);
  }
}

.OnePlanetPlate,
.AveragePerson {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  .line {
    border-top: 2px solid black;
    height: 2px;
    width: 18px;
    margin-left: 9px;
  }

  .label {
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 0.8em;
    font-family: "WWF", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 300;
    color: #552f25;
    letter-spacing: 1px;
    span {
      margin-right: 3px;
      font-size: 1.3em;
    }
  }
}

.AveragePerson {
  bottom: 46px;
  .line {
    border-top-style: dotted;
  }
}

.OnePlanetPlate {
  bottom: -3px;
}
</style>
