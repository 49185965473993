<template>
  <div class="intro">
    <span class="close click-event" v-on:click="$emit('closeIntro')">
      <Icon name="close_grey" />
    </span>
    <splide :options="options" ref="tipsCarousel">
      <splide-slide v-for="(intro, index) in intros" :key="index">
        <div
          :class="['intro__container', intro.style]"
        >
          <div class="intro__content">
            <img
              v-if="intro.id === 'first'"
              src="@/assets/WWF_Logo.svg"
              class="intro-logo"
            />
            <div
              class="intro-image"
              :class="intro.id === 'first' ? 'first' : ''"
              v-bind:style="{
                backgroundImage:
                  'url(' + 'assets/intro/' + intro.imageSrc + ')',
              }"
            ></div>

            <div class="intro-text">
              <h2 class="intro__content-title">{{ intro.title }}</h2>
              <p>{{ intro.text }}</p>
              <p v-if="intro.text2" style="margin-top: 0.5rem">{{ intro.text2 }}</p>
            </div>

            <LanguageSwitch v-if="intro.id === 'first' && !mobileAppView" />

            <div class="splide__arrows">
              <button class="splide__arrow splide__arrow--prev" v-on:click="goToPrevSlide()">
                <Icon name="chevron_right" />
              </button>
              <button class="splide__arrow splide__arrow--next" v-on:click="goToNextSlide()">
                <Icon name="chevron_right" />
              </button>
            </div>

            <Button
              v-if="intro.id === 'fourth'"
              :typeClass="'intro-btn'"
              :type="'is-primary'"
              :size="'is-medium'"
              @click.native="$emit('closeIntro')"
            />
          </div>
        </div>
      </splide-slide>
    </splide>
  </div>
</template>
<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import Icon from "./Icon.vue";
import Button from "@/components/Button.vue";
import LanguageSwitch from "./LanguageSwitch.vue";
import { get } from "vuex-pathify";

export default {
  components: {
    Splide,
    SplideSlide,
    Icon,
    Button,
    LanguageSwitch
  },
  data() {
    return {
      options: {
        rewind: false,
        perPage: 1,
        gap: "0",
        arrows: false,
        width: "100%",
      },
    };
  },
  computed: {
    mobileAppView: get("mobileAppView"),
    intros() {
      if (this.$parent.$i18n.locale === 'sv') {
        return this.$store.state.intro;
      } else {
        return this.$store.state.introEn;
      }
    },
  },
  methods: {
    goToNextSlide() {
      if (this.$refs.tipsCarousel.index == 3) {
        this.$emit("closeIntro");
      } else {
        this.$refs.tipsCarousel.go("+1");
      }
    },
    goToPrevSlide() {
      this.$refs.tipsCarousel.go("-1");
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/css/colors.scss";
@import "../assets/css/mixins.scss";

.intro {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 60;
  content: " ";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);

  &__container {
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: $white;
    border-radius: 40px;
    color: $brown;
    overflow: hidden;
    height: calc(100% - 40px);
    margin: 0 auto;
    position: relative;
    width: calc(100% - 40px);

    @include for-tablet-portrait-up() {
      max-width: 600px;
    }

    &.light {
      background-color: $primary;
      color: $brown;
    }

    &.green {
      background-color: $green;
      color: $mangfald;
    }

    .intro-btn {
      flex: 0 1 auto;
      width: calc(100% - 40px);
      margin-top: 50px;
    }
  }

  &__content {
    padding-bottom: 90px;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;

    @include for-tablet-portrait-up() {
      top: 65%;
    }

    &-title {
      text-transform: capitalize;
    }
  }
}

.splide__slide {
  opacity: 1 !important;
  top: 20px !important;
  left: 20px !important;
  right: 20px;
  bottom: 20px;

  h2 {
    font-size: 1.8em !important;
    margin: 15px auto !important;

    @include for-small-phone-up() {
      font-size: 2.2em !important;
      margin: 30px auto !important;
    }
  }

  .intro-image {
    flex: 1;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    max-height: 40vh;
    width: 100%;

    &.first {
      background-position: right top;
    }
  }

  .intro-logo {
    position: absolute;
    top: 40px;
    left: 40px;
    width: 90px;
    height: auto;
  }

  .intro-text {
    flex: 1;
    background-size: cover;
    padding: 0 20px 0 20px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  p {
    margin: 0 auto;
  }

  h2 {
    padding: 0 20px;
  }

  h2,
  p {
    max-width: 500px;

    flex: 0 1 auto;
  }
}

.close {
  position: absolute;
  right: 35px;
  top: 35px;
  z-index: 10;

  @include for-tablet-portrait-up() {
    right: calc(50% - 300px);
    transform: translateX(-50%);
  }
}

.splide {
  margin: 0 auto;
}
</style>
<style lang="scss">
@import "../assets/css/colors.scss";
@import "../assets/css/mixins.scss";

.intro {
  .splide__arrow--prev {
    left: 1em;
    @include for-tablet-portrait-up() {
      left: 2em;
    }

    .wwf-icon {
      transform: rotate(-180deg);
    }
  }

  .splide__arrow--next {
    right: 1em;
    @include for-tablet-portrait-up() {
      right: 2em;
    }
  }
}

.splide,
.splide__track,
.splide__list,
.splide__slide {
  height: 100%;
}

.splide__arrow {
  background-color: #E8E7D5;
  opacity: 1;

  .wwf-icon {
    display: flex;
  }
}

.splide__arrow--prev {
  @include for-tablet-portrait-up() {
    left: calc(50% - 280px) !important;
  }
}

.splide__arrow--next {
  @include for-tablet-portrait-up() {
    right: calc(50% - 280px) !important;
  }
}

.splide__pagination {
  bottom: calc(25px + 5%);

  &__page {
    background: $primary-light;
    height: 15px;
    margin: 0 10px;
    width: 15px;

    &.is-active {
      background: #bcb9b8;
      transform: scale(1);
    }
  }

  li:nth-of-type(2) {
    .is-active {
      background: #9a681c;
    }
  }

  li:nth-of-type(3) {
    .is-active {
      background: #007932;
    }
  }
}
</style>
