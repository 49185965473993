<template>
  <div class="share">
    <h2>{{$t('share.title')}}</h2>
    <p>{{$t('share.text')}}</p>
    <div class="share__container">
      <ShareNetwork
        v-for="network in networks"
        :network="network.network"
        :key="network.network"
        :style="{ backgroundColor: network.color }"
        :url="sharing.url"
        :title="sharing.title"
        :description="sharing.description"
        :quote="sharing.quote"
        :hashtags="sharing.hashtags"
        :twitterUser="sharing.twitterUser"
      >
        <i :class="network.icon"></i>
        <span>{{ network.name }}</span>
      </ShareNetwork>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      sharing: {
        url: "http://matkalkylatorn.se/",
        title: "WWFs Matkalkylator",
        description: this.$t('share.description'),
        media: "http://matkalkylatorn.se/shareimg.jpg",
      },
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "fab fah fa-lg fa-facebook-f",
          color: "#1877f2",
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fab fah fa-lg fa-linkedin",
          color: "#007bb5",
        },
        {
          network: "messenger",
          name: "Messenger",
          icon: "fab fah fa-lg fa-facebook-messenger",
          color: "#0084ff",
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fah fa-lg fa-twitter",
          color: "#1da1f2",
        },
      ],
    };
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
@import "../assets/css/colors.scss";
@import "../assets/css/mixins.scss";

.share {
  padding: 0 20px;

  &__container {
    max-width: 320px;
    margin: 0 auto;
    width: 100%;
  }

  .share-network-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin: auto;
  }

  a[class^="share-network-"] {
    flex: none;
    color: $white;
    background-color: #333;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px;
  }

  a[class^="share-network-"] .fah {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 15px 10px 15px 15px;
    flex: 0 1 auto;
    width: 50px;
  }

  a[class^="share-network-"] span {
    padding: 10px;
    flex: 1 1 0%;
    font-weight: 500;
  }

  @include for-tablet-landscape-up() {
    .share-network-messenger {
      display: none !important;
    }
  }
}
</style>
