import Vue from "vue";
import Vuex from "vuex";
import pathify from "vuex-pathify";
import groceries from "./groceries.json";
import groceries_en from "./groceries_en.json";
import intro from "./intro.json";
import intro_en from "./intro_en.json";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [pathify.plugin],
  state: {
    groceries: groceries,
    groceriesEn: groceries_en,
    intro: intro,
    introEn: intro_en,
    sigillData: {
      "euekologiskt": [{
        imageSrc:"bio.jpg",
        alt:"EU Ekologiskt",
      }],
      "krav": [{
        imageSrc:"krav_marke_farg.png",
        alt:"KRAV-märket",
      }],
      "asc": [{
        imageSrc:"asc.png",
        alt:"Aquaculture Stewardship Council",
      }],
      "msc": [{
        imageSrc:"msc.png",
        alt:"Marine Stewardship Council",
      }],
      "svensktsigillnaturbete": [{
        imageSrc:"svensktsigill_naturbete.png",
        alt:"Svenskt sigill naturbeteskött",
      }]
    },
    activeTips: [
      //{ title: "Starttips", text: "Detta finns alltid med från början" },
    ],
    app: false,
    showIntro: null,
    noScroll: false,
    isMobile: true,
    activeSubpage: false,
    mobileAppView: false,
  },
  mutations: {
    itsMobile(state) {
      state.isMobile = true;
    },
    itsNotMobile(state) {
      state.isMobile = false;
    },
    toggleMobileAppView(state, value) {
      state.mobileAppView = value;
    },
    toggleShowIntro(state, value) {
      state.showIntro = value;
    },
  },
  actions: {},
  modules: {},
});
