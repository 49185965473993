<template>
  <div class="button-wrapper" v-bind:class="[typeClass]">
    <a v-if="buttonUrl" :href="buttonUrl" target="_blank">
      <b-button id="button" :type="type" :size="size" rounded :outlined="outlined">
        {{ buttonText }}
      </b-button>
    </a>
    <b-button id="button" :type="type" :size="size" rounded v-else>
      {{ buttonText }}
      <span v-if="typeClass == 'intro-btn'" class="arrow-icon">
        <Icon name="arrow_right_brown" />
      </span>
      <span v-if="typeClass == 'skafferiet-btn'" class="skafferi-icon">
        <Icon name="skafferi" />
      </span>
      <span v-if="typeClass == 'done-btn' || typeClass == 'arrow-btn'">
        <Icon name="arrow_right_brown_2" />
      </span>
    </b-button>
  </div>
</template>
<script>
import Icon from "./Icon.vue";
import { get } from "vuex-pathify";

export default {
  props: {
    typeClass: String,
    size: String,
    type: String,
    outlined: {
      type: Boolean,
      required: false,
    },
  },
  components: { Icon },
  data() {
    return {
      buttonUrl: "",
      buttonText: "",
    };
  },
  computed: {
    mobileAppView: get("mobileAppView"),
    currentLang() {
      return this.$parent.$i18n.locale;
    }
  },
  methods: {
    setButtonData() {
      if (this.typeClass == "meat") {
        this.buttonUrl = this.mobileAppView ? "wwf-matguiden://kottguiden" : "https://www.wwf.se/kottguiden/";
        this.buttonText = this.$t('button.meat');
      }
      if (this.typeClass == "fish") {
        this.buttonUrl = this.mobileAppView ? "wwf-matguiden://fiskguiden" : "https://www.wwf.se/fiskguiden/";
        this.buttonText = this.$t('button.fish');
      }
      if (this.typeClass == "veg") {
        this.buttonUrl = this.mobileAppView ? "wwf-matguiden://vegoguiden" : "https://www.wwf.se/vegoguiden/";
        this.buttonText = this.$t('button.veg');
      }
      if (this.typeClass == "meat-small") {
        this.buttonUrl = this.mobileAppView ? "wwf-matguiden://kottguiden" : "https://www.wwf.se/kottguiden/";
        this.buttonText = this.$t('button.meatSmall');
      }
      if (this.typeClass == "fish-small") {
        this.buttonUrl = this.mobileAppView ? "wwf-matguiden://fiskguiden" : "https://www.wwf.se/fiskguiden/";
        this.buttonText = this.$t('button.fishSmall');
      }
      if (this.typeClass == "veg-small") {
        this.buttonUrl = this.mobileAppView ? "wwf-matguiden://vegoguiden" : "https://www.wwf.se/vegoguiden/";
        this.buttonText = this.$t('button.vegSmall');
      }
      if (this.typeClass == "intro-btn") {
        this.buttonText = this.$t('button.intro');
      }
      if (this.typeClass == "skafferiet-btn") {
        this.buttonText = this.$t('button.skafferiet');
      }
      if (this.typeClass == "share-btn") {
        this.buttonText = this.$t('button.share');
      }
      if (this.typeClass == "done-btn") {
        this.buttonText = this.$t('button.done');
      }
      if (this.typeClass == "arrow-btn") {
        this.buttonText = "";
      }
      if (this.typeClass == "clear-btn") {
        this.buttonText = this.$t('button.clear');
      }
      if (this.typeClass == "mangfald-btn") {
        this.buttonUrl = "https://www.wwf.se/mat-och-jordbruk/";
        this.buttonText = this.$t('button.mangfald');
      }
    },
  },
  created() {
    this.setButtonData();
  },
  watch: {
    typeClass() {
      this.setButtonData();
    },
    currentLang() {
      this.setButtonData();
    }
  },
};
</script>
<style lang="scss">
@import "../assets/css/colors.scss";
@import "../assets/css/mixins.scss";

#button {
  font-family: "WWF", Helvetica, Arial, sans-serif;
  width: 100%;
  max-width: 300px;
  border-style: none;

  &.is-large {
    font-size: 24px;
    margin: 20px 0;
  }

  &.is-outlined {
    border-style: solid;
    border-width: 2px;
  }

  &.is-primary.is-outlined {
    color: #552f25;
  }
}

.skafferiet-btn {
  &.wiggle #button {
    display: inline-block;
    animation: wiggle 2.7s infinite;
  }

  &.wiggle:hover #button {
    animation: none;
  }

  #button {
    bottom: 20px;
    left: 50%;
    position: fixed;
    transform: translateX(-50%);
    z-index: 9;
  }
}

.arrow-btn #button {
  height: 38px;
  padding: 0;
  width: 38px;
}

.done-btn,
.clear-btn {
  #button {
    text-transform: uppercase;
    min-width: 200px;
    width: 100%;
  }
}

.clear-btn {
  margin-bottom: 95px;

  @include for-small-phone-up() {
    margin-bottom: 105px;
  }

  @include for-tablet-landscape-up() {
    margin-bottom: 30px;
  }
}

.done-btn {
  #button {
    padding-right: 0.5em;

    & > span {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
}

.arrow-icon,
.skafferi-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  @include for-small-phone-up() {
    right: 15px;
  }
}

.intro-btn > #button > span {
  margin-right: 15px;
  line-height: 23px;
}
</style>
