<template>
  <div
    class="tip"
    v-bind:class="[tip.style, expanded, collapsed]"
    :style="getTipStyling(tip.baseColor)"
  >
    <div class="tip__content">
      <h4
        v-if="expanded"
        v-bind:style="{
          color: getTextColor(tip.baseColor),
        }"
      >
        {{ tip.subtitle }}
      </h4>
      <h3
        v-bind:style="{
          color: getTitleColor(tip.baseColor),
        }"
      >
        <span v-if="collapsed"> {{ tip.subtitle }} - </span> {{ tip.title }}
      </h3>
      <p>
        {{ tip.text }}
      </p>
      <div
        v-for="(subtip, index) in tip.subtips"
        :key="'subtip_' + index"
        class="subtip"
      >
        <BioBadge :type="subtip.bm"></BioBadge>
        <h5
          v-bind:style="{
            color: getTextColor(tip.baseColor),
          }"
        >
          <span class="subtip__bold">{{ bmValue(subtip.bm) }}</span>
          {{ subtip.title }}
        </h5>
        <p>{{ subtip.text }}</p>
        <span v-for="(sigill, index) in subtip.sigill" :key="index">
          <img
            v-for="(eachSigill, index) in sigillData[sigill.sigill]"
            :key="index"
            :src="'assets/badges/' + eachSigill.imageSrc"
            :alt="eachSigill.alt"
            class="sigill"
          />
        </span>
      </div>
      <Button
        v-if="tip.button"
        :typeClass="tip.button"
        :type="''"
        :size="'is-large'"
      />
      <p v-if="$i18n.locale == 'en'" class="text english-only">{{$t('home.englishOnly')}}</p>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import BioBadge from "@/components/BioBadge.vue";
import Button from "@/components/Button.vue";
export default {
  props: {
    tip: Object,
    expanded: String,
    collapsed: String,
  },
  components: {
    BioBadge,
    Button,
  },
  data() {
    return {
      sysInfo: { width: 0, height: 0 },
    };
  },
  computed: {
    sigillData: get("sigillData"),
   
  },
  methods: {

     getTipStyling (baseColor) {
      let bgColor = this.getBackgroundColor(baseColor);
      let bgColorWithAlpha = this.$chroma(bgColor).alpha(0.1);
      return {
        backgroundColor: bgColor,
        '--backgroundColor': bgColor,
        '--backgroundColorAlpha': bgColorWithAlpha
      }
    },
    
    getBackgroundColor(baseColor) {
      return this.$chroma(baseColor).luminance(0.7);
    },
    getTitleColor(baseColor) {
      return this.$chroma(baseColor).luminance(0.1).saturate(3);
    },
    getTextColor(baseColor) {
      return this.$chroma(baseColor).luminance(0.1).saturate(2);
    },
    bmValue(type) {
      if (type == "good") {
        return this.$t('tip.good');
      }
      if (type == "medium") {
        return this.$t('tip.medium');
      }
      if (type == "bad") {
        return this.$t('tip.bad');
      }
      return "";
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/colors.scss";
@import "../assets/css/mixins.scss";

.tip {
  background-color: grey;
  text-align: left;
  padding: 15px 25px;
  position: relative;
  
  &__content {
    margin: 0 auto;
    width: calc(100% - 2em);

    @include for-tablet-portrait-up() {
      max-width: 500px;
    }
  }

  .english-only {
    position: absolute;
    bottom: 70px;
    left: 0;
    width: 100%;
    text-align: center;
  }

  h5 {
    letter-spacing: 0.5px;
    color: #222;
  }

  h4 {
    font-size: 0.6rem;
    text-transform: uppercase;
    letter-spacing: 1.1px;
  }

  h3 {
    font-size: 1.2rem;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 8px;
    max-width: 80%;
    letter-spacing: 0.8px;
  }

  p {
    color: #222;
  }

  .subtip {
    margin-top: 3rem;

    &__bold {
      font-weight: bold;
    }
  }

  .sigill {
    height: 50px;
    margin: 10px 5px;
  }

  &.collapsed {
    border-radius: 30px;

    height: 95px;

    @include for-small-height() {
      height: 80px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
    overflow: hidden;
    cursor: pointer;
    &:hover {
      transform: scale(1.02);
    }
  }

  &.between {
    max-width: 600px;
    margin: 10px auto;
    border-radius: 30px;
    height: 150px;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      transform: scale(1.02);
    }

    .english-only {
      display: none;
    }
  }

  &.collapsed:after,
  &.between:after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 35%;
    bottom: 0;
    background: linear-gradient(
      to top,
      var(--backgroundColor) 0%,
      var(--backgroundColorAlpha) 100%
    );
  }

  @keyframes fade-in-fade-in {
  0%   { opacity: 0; }
  90%   { opacity: 0; }
  100% { opacity: 1; }
}

  &.expanded {
    min-height: 100vh;
    width: 100%;
    overflow: auto;
    border-radius: 0;
    padding: 15px 25px 240px;

    @include for-tablet-landscape-up() {
      padding: 15px 25px 180px;
    }

    h4 {
      font-size: 1rem;
      margin-top: 15vh;
    }

    h3 {
      font-size: 1.8rem;
      margin-top: 10vh;
    }

    p {
      margin-top: 2vh;
    }
  }
}
</style>
