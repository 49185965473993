var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip",class:[_vm.tip.style, _vm.expanded, _vm.collapsed],style:(_vm.getTipStyling(_vm.tip.baseColor))},[_c('div',{staticClass:"tip__content"},[(_vm.expanded)?_c('h4',{style:({
        color: _vm.getTextColor(_vm.tip.baseColor),
      })},[_vm._v(" "+_vm._s(_vm.tip.subtitle)+" ")]):_vm._e(),_c('h3',{style:({
        color: _vm.getTitleColor(_vm.tip.baseColor),
      })},[(_vm.collapsed)?_c('span',[_vm._v(" "+_vm._s(_vm.tip.subtitle)+" - ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.tip.title)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.tip.text)+" ")]),_vm._l((_vm.tip.subtips),function(subtip,index){return _c('div',{key:'subtip_' + index,staticClass:"subtip"},[_c('BioBadge',{attrs:{"type":subtip.bm}}),_c('h5',{style:({
          color: _vm.getTextColor(_vm.tip.baseColor),
        })},[_c('span',{staticClass:"subtip__bold"},[_vm._v(_vm._s(_vm.bmValue(subtip.bm)))]),_vm._v(" "+_vm._s(subtip.title)+" ")]),_c('p',[_vm._v(_vm._s(subtip.text))]),_vm._l((subtip.sigill),function(sigill,index){return _c('span',{key:index},_vm._l((_vm.sigillData[sigill.sigill]),function(eachSigill,index){return _c('img',{key:index,staticClass:"sigill",attrs:{"src":'assets/badges/' + eachSigill.imageSrc,"alt":eachSigill.alt}})}),0)})],2)}),(_vm.tip.button)?_c('Button',{attrs:{"typeClass":_vm.tip.button,"type":'',"size":'is-large'}}):_vm._e(),(_vm.$i18n.locale == 'en')?_c('p',{staticClass:"text english-only"},[_vm._v(_vm._s(_vm.$t('home.englishOnly')))]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }