<template>
  <div>
    <b-modal class="wwf-modal" v-model="active" v-on:close="$emit('close')">
      <Icon
        class="click-event"
        name="close_grey"
        @click.native="$emit('close')"
      />
      <div class="wwf-modal__content">
        <div v-if="type === 'share'">
          <Share />
        </div>
        <div v-if="type === 'info'">
          <h2 v-if="infoContent.label">{{ infoContent.label }}</h2>

          <p class="infoContent" v-if="infoContent.text">
            {{ infoContent.text }}
          </p>
          <a
            :href="infoContent.infoLink"
            target="_blank"
            class="ccBadge-wrapper"
          >
            <svg class="ccBadge" viewBox="0 0 179.08 190.39">
              <defs></defs>
              <path
                class="cls-1"
                d="M96,47.39l-.19.54L88.69,46l.14-.55.11-.36a10.35,10.35,0,0,1,20.22,3.07A10.21,10.21,0,0,1,107.34,54l0,0a10.34,10.34,0,0,1-8.5,4.43H95.59l5.75-8.76a2.75,2.75,0,0,0,.43-1.54,3,3,0,0,0-5.69-1.11Zm-.9,3.89a13.88,13.88,0,0,1-2.82,3.24A13.69,13.69,0,1,1,95,36.65l-7.35,1.94a6.85,6.85,0,1,0,0,10.79Z"
              ></path>
              <text></text>
              <circle class="cls-2" cx="89.58" cy="91.25" r="70.36"></circle>
              <text
                class="cls-3"
                dominant-baseline="middle"
                text-anchor="middle"
                x="50%"
                y="50%"
              >
                {{ infoContent.kgco2e }}
              </text>
              <text
                class="cls-4"
                transform="matrix(0.54, -0.84, 0.84, 0.54, 21.31, 55.06)"
              >
                C
              </text>
              <text
                class="cls-4"
                transform="matrix(0.65, -0.76, 0.76, 0.65, 28.22, 44.56)"
              >
                L
              </text>
              <text
                class="cls-4"
                transform="translate(33.45 38.49) rotate(-44.98)"
              >
                I
              </text>
              <text
                class="cls-4"
                transform="translate(36.61 34.86) rotate(-37.61)"
              >
                M
              </text>
              <text
                class="cls-4"
                transform="matrix(0.89, -0.46, 0.46, 0.89, 48.62, 25.76)"
              >
                A
              </text>
              <text
                class="cls-4"
                transform="translate(59.35 20.25) rotate(-19.47)"
              >
                T
              </text>
              <text
                class="cls-4"
                transform="translate(68.46 17.05) rotate(-12.5)"
              >
                E
              </text>
              <text
                class="cls-4"
                transform="translate(77.28 15.21) rotate(-7.88)"
              ></text>
              <text
                class="cls-4"
                transform="translate(80.65 14.63) rotate(-3.2)"
              >
                F
              </text>
              <text
                class="cls-4"
                transform="translate(89.72 13.97) rotate(4.92)"
              >
                O
              </text>
              <text
                class="cls-4"
                transform="translate(102.69 15.08) rotate(14.61)"
              >
                O
              </text>
              <text
                class="cls-4"
                transform="translate(115.29 18.49) rotate(23.08)"
              >
                T
              </text>
              <text
                class="cls-4"
                transform="matrix(0.86, 0.51, -0.51, 0.86, 124.14, 22.23)"
              >
                P
              </text>
              <text
                class="cls-4"
                transform="matrix(0.79, 0.62, -0.62, 0.79, 133, 27.43)"
              >
                R
              </text>
              <text
                class="cls-4"
                transform="matrix(0.72, 0.69, -0.69, 0.72, 141.24, 34.1)"
              >
                I
              </text>
              <text
                class="cls-4"
                transform="translate(144.87 37.31) rotate(50.3)"
              >
                N
              </text>
              <text
                class="cls-4"
                transform="translate(152.69 46.86) rotate(58.41)"
              >
                T
              </text>
              <text
                class="cls-4"
                transform="translate(9.24 130.35) rotate(59.93)"
              >
                C
              </text>
              <text
                class="cls-4"
                transform="translate(16.63 142.8) rotate(50.79)"
              >
                A
              </text>
              <text
                class="cls-4"
                transform="translate(25.57 153.45) rotate(42.4)"
              >
                R
              </text>
              <text
                class="cls-4"
                transform="translate(34.7 161.64) rotate(34.45)"
              >
                B
              </text>
              <text
                class="cls-4"
                transform="translate(45.2 168.79) rotate(25.55)"
              >
                O
              </text>
              <text
                class="cls-4"
                transform="matrix(0.96, 0.28, -0.28, 0.96, 58.9, 175.12)"
              >
                N
              </text>
              <text
                class="cls-4"
                transform="matrix(0.99, 0.12, -0.12, 0.99, 72.72, 178.94)"
              >
                C
              </text>
              <text
                class="cls-4"
                transform="matrix(1, -0.02, 0.02, 1, 86.87, 180.35)"
              >
                L
              </text>
              <text
                class="cls-4"
                transform="translate(96.44 180.27) rotate(-8.62)"
              >
                O
              </text>
              <text
                class="cls-4"
                transform="translate(111.27 177.81) rotate(-17.83)"
              >
                U
              </text>
              <text
                class="cls-4"
                transform="matrix(0.89, -0.45, 0.45, 0.89, 124.03, 173.58)"
              >
                D
              </text>
              <text
                class="cls-4"
                transform="translate(136 167.23) rotate(-32.77)"
              >
                .
              </text>
              <text
                class="cls-4"
                transform="matrix(0.78, -0.63, 0.63, 0.78, 140.55, 164.49)"
              >
                C
              </text>
              <text
                class="cls-4"
                transform="translate(151.77 155.25) rotate(-48.42)"
              >
                O
              </text>
              <text
                class="cls-4"
                transform="translate(161.8 143.8) rotate(-58.83)"
              >
                M
              </text>
              <text class="cls-5" transform="translate(56.49 129.46)">
                kg CO
                <tspan x="50.51" y="3.65">2</tspan>
                <tspan class="cls-6" x="59.95" y="0">e</tspan>
              </text>
              <text class="cls-5" transform="translate(64.82 148.55)">
                per kg
              </text>
            </svg>
          </a>
          <br />
          <br />
          {{$t('modal.text')}}
          <br />
          <a :href="infoContent.infoLink" target="_blank">
            <button class="carboncloudButton">
              <svg class="ccLogo" viewBox="70 30 40 30">
                <path
                  class="carboncloudLogo"
                  d="M96,47.39l-.19.54L88.69,46l.14-.55.11-.36a10.35,10.35,0,0,1,20.22,3.07A10.21,10.21,0,0,1,107.34,54l0,0a10.34,10.34,0,0,1-8.5,4.43H95.59l5.75-8.76a2.75,2.75,0,0,0,.43-1.54,3,3,0,0,0-5.69-1.11Zm-.9,3.89a13.88,13.88,0,0,1-2.82,3.24A13.69,13.69,0,1,1,95,36.65l-7.35,1.94a6.85,6.85,0,1,0,0,10.79Z"
                ></path>
              </svg>

              <span>{{ infoContent.kgco2e }} kg CO<sub>2</sub>e/kg</span>
            </button>
          </a>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Share from "@/components/Share.vue";
import Icon from "@/components/Icon.vue";

export default {
  props: {
    active: Boolean,
    type: String,
    infoContent: { type: Object, required: false },
  },
  components: { Share, Icon },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
@import "../assets/css/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

.wwf-modal {
  border-radius: 40px;
  margin: 20px;
  z-index: 100 !important;

  &__content {
    padding-top: 3rem;
  }

  .modal-background {
    background-color: $white;
  }

  .modal-content {
    max-height: 100%;
    height: 100%;
    max-width: 100% !important;
  }

  .modal-close {
    display: none;
  }

  .wwf-icon {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.modal {
  z-index: 100;

  &:focus {
    outline: none;
  }

  &::before {
    content: " ";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: -1;
  }
}

.infoContent {
  padding: 0 20px;
}

.ccBadge-wrapper {
  display: none;
}

.ccBadge {
  margin-top: 20px;
  width: 160px;
}

/** CC specific */

.cls-1,
.cls-4 {
  fill: #091f1f;
}
.cls-1 {
  fill-rule: evenodd;
}
.cls-2 {
  fill: none;
}
.cls-3 {
  font-size: 48.1px;
  letter-spacing: -0.02em;
}
.cls-3,
.cls-5 {
  fill: #1e1e1c;
}
.cls-3,
.cls-4,
.cls-5 {
  font-family: Poppins-Bold, Poppins;
  font-weight: 700;
}
.cls-4,
.cls-5 {
  font-size: 16.67px;
}
.cls-6 {
  letter-spacing: 0.09em;
}

.carboncloudButton {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: 400;
  font-size: 1.2rem;
  border: none;
  display: flex;
  align-items: center;
  margin: 5px auto;
  cursor: pointer;

  &:hover,
  &:active {
    transform: scale(1.05);
  }

  sub {
    font-size: 0.8rem;
  }
}

.ccLogo {
  width: 30px;
  margin-right: 10px;

  .carboncloudLogo {
    fill: white;
  }
}
</style>
