<template>
  <div class="container">
    <Header />
    <div class="sub-page">
      <h2>{{$t('difference.title')}}</h2>
      <p>{{$t('difference.text')}}
      <br /><br />
      <span v-for="list in $t('difference.list')" :key="list">{{list}}<br /></span>
      <br>
      {{$t('difference.text2')}}<a href="https://www.wwf.se/mat-och-jordbruk/receptbanken/" target="_blank" rel="noopener">{{$t('difference.linkText')}}</a>{{$t('difference.text3')}}</p>
    </div>
  </div>
</template>
<script>
import { get } from "vuex-pathify";
import Header from "@/components/Header.vue";
export default {
  name: "Difference",
  components: {
    Header,
  },
  computed: {
    page: get("pages@skillnad"),
  },
  created() {
    this.$store.state.app = false;
  },
  mounted() {
    this.$store.state.activeSubpage = true;
    this.$store.state.noScroll = false;
  },
  destroyed() {
    this.$store.state.activeSubpage = false;
  },
};
</script>
<style lang="scss">
#app.subpage-active {
  width: 100%;
  margin-left: 0;

  .sub-page {
    max-width: 600px;
    margin: 0 auto;
  }
}

.sub-page {
  padding: 60px 2rem;
  text-align: left;

  h3 {
    font-size: 1.4rem;
  }

  p {
    margin: 1rem 0 3rem;
  }
}
</style>
