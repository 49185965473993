<template>
  <div class="container">
    <Intro v-if="showIntro" v-on:closeIntro="closeIntro()" />

    <Header v-if="!mobileAppView" />

    <Plate class="click-event" :cubeData="climateList" :groceries="groceries" :showSliders="showSliders"
      @click.native="openSkafferiet" :key="currentLang === 'sv' ? 0 : 1"></Plate>

    <MediaQueryProvider @change:tabletUp="onResponsiveChange" :queries="$options.queries">
    </MediaQueryProvider>

    <div class="co2">
      <div class="co2-title">
        <div class="co2-numbers">{{ co2_total_rounded }}</div>
        kg CO<sub>2</sub>e
      </div>
      <Meter :kgco2="co2_total"></Meter>
    </div>

    <Button :typeClass="'skafferiet-btn'" :type="'is-primary'" :size="'is-large'" @click.native="openSkafferiet"
      v-if="isMobile" />

    <transition name="fade">
      <div class="results" v-if="!desktopModifier">
        <div class="result zero" v-if="climateList.length == 0">
          <p>
            {{ $t('home.text') }}
            <span v-if="!isMobile">{{ $t('home.drag') }}</span>
          </p>
        </div>

        <div class="result" v-else>
          {{ $t('home.result', { total: co2_total_rounded }) }}
        </div>
      </div>
    </transition>

    <tips ref="tips" :activeTipsLocal="activeTipsLocal" :showSliders="showSliders" :climateList="climateList"></tips>

    <transition name="fade">
      <div class="greatest-container" v-if="!desktopModifier && climateList.length > 0">
        <div class="result">
          <h2>{{ $t('home.resultTitle') }}</h2>
          <div class="zero" v-if="climateList.length == 0">
            <p>{{ $t('home.zero') }}</p>
          </div>

          <div v-for="(grocery, index) in climateList" :key="'grocery_climate_' + index" :label="grocery.label"
            class="climate-effect">
            <div class="climate-listing">
              <div class="listing-meta">
                <h3 class="result-subtitle">{{ grocery.label }}</h3>
                <label class="currentCo2">
                  {{ replaceComma(round(grocery.currentCo2, 2)) }}
                  kg CO<sub>2</sub>e
                </label>
              </div>

              <div class="climate-bar">
                <div class="climate-bar-fill" v-bind:style="{
                  backgroundColor: grocery.baseColor,
                  width: (grocery.currentCo2 / maxKgCo2E) * 100 + '%',
                }"></div>
                <label>{{ round(grocery.currentCo2, 2) }}kg CO2e</label>
              </div>
            </div>
          </div>
          <p class="text">
            {{ climateList[0].resultText }}
            <Button v-for="(button, index) in displayButtons(
              climateList[0].tip.button
            )" :key="'button_' + index" :typeClass="climateList[0].tip.button != button ? button + '-small' : button
  " :type="'is-primary'" :size="'is-large'" :outlined="climateList[0].tip.button == button ? false : true" />
          </p>
          <p v-if="$i18n.locale == 'en'" class="text">{{ $t('home.englishOnly') }}</p>
          <p>
            {{ $t('home.powered') }}
            <a href="https://carboncloud.com/" class="cc-link" target="_blank"><img class="cc-logo"
                src="assets/carbon-cloud-logo.png" alt="CarbonCloud" /></a>
          </p>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div class="share-container" v-if="!desktopModifier && climateList.length > 0">
        <div class="result">
          <h2>{{ $t('share.title') }}</h2>
          <p class="text">
            {{ $t('share.text') }}
          </p>
          <Button :typeClass="'share-btn'" :type="'is-share'" :size="'is-large'"
            @click.native="isModalActive = !isModalActive" />
          <Modal :active="isModalActive" v-on:close="isModalActive = false" type="share" />
        </div>
      </div>
    </transition>

    <transition name="bounce">
      <div id="skafferiet-wrapper" v-if="showSkafferiet">
        <div id="skafferiet">
          <span class="center-button sticky-button" v-if="isMobile">
            <Button :typeClass="co2_total > 0 ? 'done-btn' : 'arrow-btn'" :type="'is-primary'"
              @click.native="closeSkafferiet" />
          </span>
          <b-field class="field-container" v-for="(grocery, index) in local_groceries" :key="'grocery_' + index"
            :label="grocery.label">
            <b-slider v-model="grocery.sliderValue" :custom-formatter="(val) => getTooltip(val, index, grocery.max)"
              :min="grocery.min" :max="grocery.max" :step="grocery.step" :class="['wwf-slider', grocery.imageCube]"
              indicator />
            <v-style>
              .{{ grocery.imageCube }} .b-slider-thumb span { background-image:
              url('assets/cubes_mini/{{ grocery.cubeUrl }}'); }
            </v-style>
            <span class="info" :style="grocery.infoText ? 'right: -26px;' : 'right: 6px;'">
              <span class="tick">
                {{
                  replaceComma(grocery.sliderValue) + " " + grocery.unitLabel
                }}
              </span>
              <span v-if="grocery.infoText" class="click-event" v-on:click="
                getInfoContent(
                  grocery.label,
                  grocery.infoText,
                  grocery.infoLink,
                  grocery.kgco2e
                )
                ">
                <Icon name="info" />
              </span>
            </span>
          </b-field>
          <span class="center-button end-buttons">
            <Button :typeClass="co2_total > 0 ? 'done-btn' : 'arrow-btn'" :type="'is-primary'"
              @click.native="closeSkafferiet" v-if="isMobile" class="extra-result-button" />
            <Button :typeClass="'clear-btn'" :type="'is-primary'" @click.native="resetSliders" />
          </span>
        </div>
      </div>
    </transition>
    <Modal :active="isInfoModalActive" v-on:close="isInfoModalActive = false" type="info" :infoContent="infoContent" />
  </div>
</template>

<script>
import Vue from "vue";
import { sync, get } from "vuex-pathify";
import { union } from "lodash";
import Meter from "@/components/Meter.vue";
import Tips from "@/components/Tips.vue";
import Plate from "@/components/Plate.vue";
import Intro from "@/components/Intro.vue";
import Icon from "@/components/Icon.vue";
import Modal from "@/components/Modal.vue";
import Header from "@/components/Header.vue";
import Button from "@/components/Button.vue";
import { MediaQueryProvider } from "vue-component-media-queries";
import { gsap } from "gsap";
import { EasePack } from "gsap/EasePack";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});

const queries = {
  tabletUp: "(min-width: 900px)",
};

export default {
  name: "Home",
  components: {
    Intro,
    Meter,
    Tips,
    Plate,
    Icon,
    Header,
    MediaQueryProvider,
    Modal,
    Button,
  },
  data() {
    return {
      value: false,
      showSliders: false,
      showTipModal: false,
      activeGroceries: [],
      isInfoModalActive: false,
      isModalActive: false,
      infoContent: { type: Object, require: false },
      breakPoint: 900,
    };
  },
  computed: {
    activeTips: sync("activeTips"),
    app: get("app"),
    showIntro: get("showIntro"),
    isMobile: get("isMobile"),
    mobileAppView: get("mobileAppView"),
    groceries() {
      if (this.currentLang === 'sv') {
        return this.$store.state.groceries;
      } else {
        return this.$store.state.groceriesEn;
      }
    },
    currentLang() {
      return this.$parent.$i18n.locale;
    },
    desktopModifier() {
      if (this.$store.state.isMobile) {
        return this.showSliders;
      }
      return false;
    },
    showSkafferiet() {
      return this.showSliders;
    },
    local_groceries() {
      return this.groceries.map((grocery) => {
        grocery.currentCo2 =
          ((grocery.sliderValue * grocery.conversionFactor) / 1000) *
          grocery.kgco2e;
        return grocery;
      });
    },
    numTips() {
      return "20px";
    },
    co2_total() {
      let co2 = 0;
      this.groceries.map((grocery) => {
        co2 += grocery.currentCo2;
      });
      return co2;
    },
    co2_total_rounded() {
      let co2Total = this.round(this.co2_total, 1);
      if (this.currentLang === 'sv') co2Total = co2Total.toString().replace(".", ",");
      return co2Total;
    },
    maxKgCo2E() {
      let tempMax = 0;
      this.groceries.map((grocery) => {
        if (grocery.currentCo2 > tempMax) {
          tempMax = grocery.currentCo2;
        }
      });
      return tempMax;
    },
    activeTipsLocal() {
      this.groceries.map((grocery) => {
        const index = this.activeTips.indexOf(grocery.tip);
        if (grocery.sliderValue > 0) {
          //slider is active
          //console.log(grocery.label, "is active");
          let topIndex = this.activeTips.length - 1;
          if (
            index > -1 &&
            grocery.lastSliderValue != grocery.sliderValue &&
            index != topIndex
          ) {
            //console.log(
            //"there was a change at:" + grocery.label + ". Put on top."
            //);

            // console.log(topIndex, this.activeTips.length, index);

            this.activeTips.splice(index, 1);
            this.activeTips.splice(topIndex, 0, grocery.tip);
          }

          grocery.lastSliderValue = grocery.sliderValue;
          if (index < 0) {
            //not in array
            if (grocery.tip) {
              //is there a tip on this grocery?
              grocery.tip.baseColor = grocery.baseColor;
              this.activeTips.push(grocery.tip);

              this.activeGroceries.push(grocery);
            }
          }
        } else {
          //slider is inactive (0)
          if (index > -1) {
            //its in the array
            this.activeTips.splice(index, 1);
            this.activeGroceries.splice(index, 1);
          }
        }
      });
      return this.activeTips;
    },
    climateList() {
      return this.groceries
        .filter(function (item) {
          return item.currentCo2 > 0;
        })
        .sort((b, a) => (a.currentCo2 > b.currentCo2 ? 1 : -1));
    },
  },
  queries,
  methods: {
    displayButtons(first) {
      var buttonObjects = [];
      this.groceries.forEach(function (grocery) {
        buttonObjects = union(buttonObjects, [grocery.tip.button]);
      });
      buttonObjects.splice(buttonObjects.indexOf(first), 1);
      buttonObjects.unshift(first);
      return buttonObjects;
    },
    initGsap() {
      gsap.registerPlugin(ScrollToPlugin);
      gsap.registerPlugin(EasePack);
      //setTimeout(() => gsap.to(window, { duration: 2, scrollTo: 400 }), 1000);
    },
    handleResizedWindow() {
      if (window.innerWidth > this.breakPoint) {
        //console.log("its not mobile");
        this.$store.commit("itsNotMobile");
        this.showSliders = true;
      } else {
        //console.log("its mobile");
        this.$store.commit("itsMobile");
      }
      //console.log("handle resize", window.innerWidth);
    },
    closeIntro() {
      this.$store.commit('toggleShowIntro', false);
      // Session on site and local on Mobile App site
      sessionStorage.setItem("showIntroStorage", false);
      localStorage.setItem("showIntroStorage", false);
      // if (this.mobileAppView) {
      // } else {

      // }
      this.$store.state.noScroll = false;
      setTimeout(() => this.openSkafferiet(), 450);
    },
    openSkafferiet() {
      if (this.isMobile) {
        this.showSliders = !this.showSliders;
        this.$store.state.noScroll = true;

        gsap.to(window, { duration: 1, scrollTo: 0, ease: "Strong.easeOut" });
      }
    },
    closeSkafferiet() {
      this.showSliders = !this.showSliders;
      this.$store.state.noScroll = false;
    },
    round(value, decimals) {
      return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
    },
    replaceComma(nr) {
      let str = nr.toString();
      if (this.currentLang === 'sv') str = str.replace(".", ",");
      return str;
    },
    resetSliders() {
      this.groceries.map((grocery) => {
        grocery.sliderValue = 0;
      });
    },
    getTooltip(val, index, max) {
      let firstLimit = 0.01 * max;
      let spanRange = 0.05 * max;

      if (this.groceries[index].tooltips && val > firstLimit) {
        //console.log("this one has tooltips");

        let closest = this.groceries[index].tooltips.reduce(function (a, b) {
          return Math.abs(b.value - val) < Math.abs(a.value - val) ? b : a;
          //return { value: a.value + b.value }; // returns object with property x
        });
        //console.log("distance", Math.abs(closest.value - val));
        if (Math.abs(closest.value - val) < spanRange) {
          return closest.text;
        }
      }
      val = this.replaceComma(val);
      return val + " " + this.groceries[index].unitLabel;
    },
    getInfoContent(label, text, infoLink, kgco2e) {
      this.isInfoModalActive = true;
      this.infoContent = { label, text, infoLink, kgco2e };
    },
    onResponsiveChange(event) {
      // const { matches, media, ...rest } = event;
      // console.log("changed", event);
      if (event.matches) {
        //its bigger than mobile
        // console.log("matches mobile");
        //this.showSliders = true;
      } else {
        //this.showSliders = false;
      }
    },
  },
  watch: {
    showSliders() {
      this.$store.state.app = this.showSliders;
    },
    activeTipsLocal: function () {
      //console.log("activetipsLocal.length", this.activeTipsLocal.length);
      //go to slide in advance in slider to avoid ugly transition later.
      this.$refs.tips.resetTipModal();
    },
    showIntro() {
      if (this.showIntro) this.$store.state.noScroll = true;
    },
    currentLang() {
      if (this.currentLang === 'sv') {
        return this.$store.state.groceries;
      } else {
        return this.$store.state.groceriesEn;
      }
    }
  },
  mounted() {
    this.initGsap();
    this.handleResizedWindow();
    window.addEventListener("resize", this.handleResizedWindow);

    //setTimeout(() => (this.showSliders = true), 2000);
  },
};
</script>
<style lang="scss">
@import "../assets/css/colors.scss";
@import "../assets/css/mixins.scss";

.co2 {
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem;
  position: absolute;
  top: 10px;
  left: 50%;
  display: grid;
  grid-template-columns: 60px 32px 60px;

  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  // -webkit-transform: translateX(-50%) scale(1.6);
  // transform: translateX(-50%) scale(1.6);
}

.co2-title {
  font-size: 19px;
  line-height: 32px;
  color: #552f25;

  .co2-numbers {
    font-size: 38px;
    min-width: 60px;
  }
}

.wwf-slider {
  z-index: 38;
}

.b-slider .b-slider-track {
  background: $primary !important;
}

.container {
  min-height: 100%;
  max-width: 100% !important;
}

.b-slider-thumb {
  border: 1px solid $primary !important;
  border-radius: 50% !important;
  height: 45px !important;
  padding: 0 !important;
  width: 45px !important;
}

.b-slider-thumb span {
  background-size: cover;
  background-repeat: no-repeat;
  height: 30px;
  margin: 0 auto;
  width: 30px;
  text-indent: -9999px;
  transform: scale(1);
}

.field-container {
  position: relative;
}

.info {
  display: flex;
  position: absolute;
  top: 0;

  .tick {
    white-space: nowrap;
  }

  .wwf-icon {
    height: 34px;
    width: 34px;
  }
}

/* START transitions */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.result.zero {
  padding-bottom: 100px;
}

//Bounce
.bounce {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
  /* ease-out */
}

.bounce-enter-active {
  animation: bounce-in 0.25s;
}

.bounce-leave-active {
  animation: bounce-in 0.25s reverse;
}

@keyframes bounce-in {
  0% {
    transform: translateY(50vh);
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    transform: translateY(0px);
  }
}

//tips-wrapper
.plate-summary-wrapper-slide {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
  /* ease-out */
}

.plate-summary-wrapper-slide-enter-active {
  animation: plate-summary-wrapper-slide-in 1.25s;
}

.plate-summary-wrapper-slide-leave-active {
  animation: plate-summary-wrapper-slide-out 1.25s;
  transition-timing-function: cubic-bezier(1, 0.58, 0, 0);
  /* ease-out */
}

@keyframes plate-summary-wrapper-slide-in {
  0% {
    transform: translateX(-100vh);
    opacity: 0;
  }

  50% {
    transform: translateX(-100vh);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes plate-summary-wrapper-slide-out {
  100% {
    transform: translateX(-100vh);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  30% {
    transform: translateX(0);
    opacity: 1;
  }

  0% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* END transitions */

.share-container,
.greatest-container {
  .text {
    margin: 50px 0;

    #button.is-small {
      font-size: 18px;
      margin: 20px 0;
      min-width: 180px;
      width: auto;
    }
  }
}

.greatest-container {
  background-color: $primary-light;

  .cc-link {
    margin-left: 4px;
    vertical-align: sub;
  }

  .cc-logo {
    width: 150px;
  }
}

.share-container {
  background-color: $light;
}

#skafferiet-wrapper {
  padding-top: 20px;
  background-color: white;
  border-radius: 30px 30px 0 0;
  position: fixed;
  bottom: 0;
  height: calc(100% - 350px);

  width: 100%;
  z-index: 25;

  @include for-small-height() {
    height: calc(100% - 240px);
    padding-top: 10px;
  }

  @include for-small-phone-up() {
    padding-top: 30px;
  }

  @include for-tablet-landscape-up() {
    width: 400px;
    height: 100%;
    left: 0;
  }

  #skafferiet {
    background-color: #fff;
    border-radius: 30px 30px 0 0;
    padding: 0 30px;
    text-align: left;
    height: 100%;

    @include for-small-height() {
      overflow-y: scroll;
    }

    @include for-tablet-landscape-up() {
      border-radius: 0;
      padding: 0 30px 30px;
    }

    //height: calc(100% - 400px - 90px - 15px);
    //min-height: 50%;

    overflow-y: scroll;
    overflow-x: hidden;

    transform: translate3d(0, 0, 0);
  }
}

.center-button {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.end-buttons .button-wrapper {
  margin: 10px;
}

.extra-result-button {
  display: none;

  @include for-small-height() {
    display: block;
  }
}

.sticky-button {
  padding-bottom: 20px;

  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: white;
  width: 100vw;
  margin-left: -30px;

  @include for-small-height() {
    position: static;
    padding-bottom: 10px;
  }

  @include for-small-phone-up() {
    padding-bottom: 30px;
  }
}

.results {
  @include for-small-height() {
    margin-top: -40px;
  }
}

.result {
  width: 100%;
  padding: 20px 50px 75px;

  &-subtitle {
    margin: 0 0 2px 0.5rem;
    text-align: left;
  }

  .progress-value {
    font-size: 14px !important;
    font-weight: 600;
  }

  .climate-listing {
    margin-bottom: 10px;

    .listing-meta {
      display: flex;

      h3,
      .currentCo2 {
        font-family: "WWF", Helvetica, Arial, sans-serif;
        font-size: 1em;
        width: 50%;
        margin: 0;
      }

      .currentCo2 {
        text-align: right;
      }
    }

    .climate-bar {
      width: 100%;
      height: 10px;
      background-color: #fff;
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      margin-top: 3px;

      .climate-bar-fill {
        height: 100%;
        transition: all 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
        /* easeOutQuart */
      }

      label {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
        font-size: 0.8em;
        color: black;
        display: none;
      }
    }
  }
}

.grocery-info {
  border-radius: 40px;
  margin: 20px;
  z-index: 55;

  &__content {
    padding-top: 3rem;
  }

  .modal-background {
    background-color: $white;
  }

  .modal-content {
    max-height: 100%;
    height: 100%;
    max-width: 100% !important;
  }

  .modal-close {
    position: absolute;
    right: 15px;
    top: 15px;

    &::before,
    &::after {
      background-color: #000000;
    }
  }
}

.share-btn {
  width: 80%;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0 auto;

  button {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

/*SHAKER*/
@keyframes wiggle {
  2% {
    -webkit-transform: translateX(calc(-50% + 3px)) rotate(2deg);
    transform: translateX(calc(-50% + 3px)) rotate(2deg);
  }

  4% {
    -webkit-transform: translateX(calc(-50% - 3px)) rotate(-2deg);
    transform: translateX(calc(-50% - 3px)) rotate(-2deg);
  }

  6% {
    -webkit-transform: translateX(calc(-50% + 3px)) rotate(2deg);
    transform: translateX(calc(-50% + 3px)) rotate(2deg);
  }

  8% {
    -webkit-transform: translateX(calc(-50% - 3px)) rotate(-2deg);
    transform: translateX(calc(-50% - 3px)) rotate(-2deg);
  }

  10% {
    -webkit-transform: translateX(calc(-50% + 2px)) rotate(1deg);
    transform: translateX(calc(-50% + 3px)) rotate(1deg);
  }

  12% {
    -webkit-transform: translateX(calc(-50% - 2px)) rotate(-1deg);
    transform: translateX(calc(-50% - 2px)) rotate(-1deg);
  }

  14% {
    -webkit-transform: translateX(calc(-50% + 2px)) rotate(1deg);
    transform: translateX(calc(-50% + 2px)) rotate(1deg);
  }

  16% {
    -webkit-transform: translateX(calc(-50% - 2px)) rotate(-1deg);
    transform: translateX(calc(-50% - 2px)) rotate(-1deg);
  }

  18% {
    -webkit-transform: translateX(calc(-50% + 1px)) rotate(0);
    transform: translateX(calc(-50% + 1px)) rotate(0);
  }

  20% {
    -webkit-transform: translateX(calc(-50% - 1px)) rotate(0);
    transform: translateX(calc(-50% - 1px)) rotate(0);
  }
}

.sharing {
  padding-bottom: 200px;
}
</style>
