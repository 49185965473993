<template>
  <div class="container">
    <Header />
    <div class="sub-page">
      <h2>{{$t('hallbarhetsaspekter.title')}}</h2>
      <p>{{$t('hallbarhetsaspekter.text')}}</p>
      <p>{{$t('hallbarhetsaspekter.text2')}}</p>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
export default {
  name: "Hallbarhetsaspekter",
  components: {
    Header,
  },
  created() {
    this.$store.state.app = false;
  },
  mounted() {
    this.$store.state.activeSubpage = true;
    this.$store.state.noScroll = false;
  },
  destroyed() {
    this.$store.state.activeSubpage = false;
  },
};
</script>
