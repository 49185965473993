<template>
  <div class="bio-badge" v-bind:class="[type]">
    <img :src="badgeUrl" />
  </div>
</template>

<script>
export default {
  props: {
    type: String,
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    badgeUrl() {
      if (this.type == "good") {
        return "assets/badges/mangfald_3.png";
      }
      if (this.type == "medium") {
        return "assets/badges/mangfald_2.png";
      }
      if (this.type == "bad") {
        return "assets/badges/mangfald_1.png";
      }
      return "assets/badges/mangfald_3.png";
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.bio-badge {
  width: 160px;
  height: auto;
  margin-left: -20px;
}
</style>
