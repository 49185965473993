<template>
  <div class="container">
    <Header />
    <div class="sub-page">
      <h2>{{$t('about.title')}}</h2>
      <p>{{$t('about.text')}}<br><br>

        {{$t('about.text2')}}<a href="https://carboncloud.com" target="_blank" rel="noopener">CarbonCloud</a>{{$t('about.text3')}}<br><br>
        
        {{$t('about.text4')}}<a href="https://wwwwwfse.cdn.triggerfish.cloud/uploads/2021/09/matkalkylatorn-metoddokument.pdf" target="_blank" rel="noopener">{{$t('about.linkText')}}</a>.<br><br>
        
        {{$t('about.text5')}}<a href="https://www.wwf.se/mat-och-jordbruk/kottguiden/" target="_blank" rel="noopener">{{$t('about.text6')}}</a>, <a href="www.wwf.se/fiskguiden/" target="_blank" rel="noopener">{{$t('about.text7')}}</a>, <a href="https://www.wwf.se/vegoguiden/" target="_blank" rel="noopener">{{$t('about.text8')}}</a>{{$t('about.text9')}}<a href="https://www.wwf.se/mat-och-jordbruk/" target="_blank" rel="noopener">{{$t('about.text10')}}</a>{{$t('about.text11')}}<a href="https://www.livsmedelsverket.se/matvanor-halsa--miljo/kostrad?AspxAutoDetectCookieSupport=1/" target="_blank" rel="noopener">{{$t('about.linkText')}}</a>.
        
        {{$t('about.text12')}}<a href="https://www.wwf.se/" target="_blank" rel="noopener">{{$t('about.text13')}}</a>{{$t('about.text14')}}<br><br>
        {{$t('about.text15')}}<a href="mailto:mat@wwf.se">{{$t('about.text16')}}</a>{{$t('about.text17')}}</p>
    </div>
  </div>
</template>
<script>
import { get } from "vuex-pathify";
import Header from "@/components/Header.vue";
export default {
  name: "About",
  components: {
    Header,
  },
  computed: {
    page: get("pages@matkalkylatorn"),
  },
  created() {
    this.$store.state.app = false;
  },
  mounted() {
    this.$store.state.activeSubpage = true;
    this.$store.state.noScroll = false;
  },
  destroyed() {
    this.$store.state.activeSubpage = false;
  },
};
</script>
