<template>
  <div class="wwf-icon">
    <img :src="iconSrc" />
  </div>
</template>

<script>
export default {
  name: "Icon",
  components: {},
  props: {
    name: { type: String, required: true },
  },
  data() {
    return {
      icons: {
        arrow_down_black: require("@/assets/icons/arrow_down_blk.svg"),
        arrow_down_brown: require("@/assets/icons/arrow_down_brwn.svg"),
        arrow_down_white: require("@/assets/icons/arrow_down_white.svg"),
        arrow_down_white_2: require("@/assets/icons/arrow_down_white2.svg"),
        arrow_right_black: require("@/assets/icons/arrow_right_blk.svg"),
        chevron_right: require("@/assets/icons/arrow_right_brown.svg"),
        arrow_right_brown: require("@/assets/icons/arrow_right_brwn.svg"),
        arrow_right_brown_2_1: require("@/assets/icons/arrow_right_brwn2_1.svg"),
        arrow_right_brown_2: require("@/assets/icons/arrow_right_brwn2.svg"),
        arrow_right_white: require("@/assets/icons/arrow_right_white.svg"),
        arrow_right_white_2: require("@/assets/icons/arrow_right_white2.svg"),
        circle_large: require("@/assets/icons/circle_large.svg"),
        circle_small: require("@/assets/icons/circle_small.svg"),
        close_grey: require("@/assets/icons/close_grey.svg"),
        close_white: require("@/assets/icons/close_white.svg"),
        close: require("@/assets/icons/close.svg"),
        info_white: require("@/assets/icons/info_white.svg"),
        info: require("@/assets/icons/info.svg"),
        menu: require("@/assets/icons/menu.svg"),
        skafferi: require("@/assets/icons/skafferi.png"),
        textbutton_large: require("@/assets/icons/textbutton_large.svg"),
        textbutton_small: require("@/assets/icons/textbutton_small.svg"),
      },
    };
  },
  computed: {
    iconSrc() {
      return this.icons[this.name];
    },
  },
};
</script>
<style lang="scss">
.wwf-icon {
  height: 38px;
  width: 38px;
}
</style>
