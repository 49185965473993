<template>
  <div id="tips-module">
    <transition name="fade">
      <div
        id="tips-result"
        class="result"
        :data-numTips="activeTipsLocal.length"
        v-if="!desktopModifier && climateList.length > 0"
      >
        <h2 class="bm">{{$t('tips.title')}}</h2>
        <div class="zero" v-if="activeTipsLocal.length == 0">
          <p>{{$t('tips.text')}}</p>
        </div>
        <transition-group name="tip-slide">
          <tip
            v-for="(tip, index) in tipsInOrder.slice()"
            :key="'tip_' + index"
            :tip="tip"
            collapsed="between"
            @click.native="goToSlide(index)"
          ></tip>
        </transition-group>
        <Button
          :typeClass="'mangfald-btn'"
          :type="'is-mangfald'"
          :size="'is-large'"
          v-if="$i18n.locale == 'sv'"
        />
      </div>
    </transition>

    <transition name="tips-wrapper-slide">
      <div
        id="tips"
        :class="showSliders ? 'collapsed' : 'expanded'"
        :data-numTips="activeTipsLocal.length"
        v-if="desktopModifier"
      >
        <transition-group name="tip-slide">
          <tip
            v-for="(tip, index) in activeTipsLocal.slice()"
            :key="'tip_' + index"
            :tip="tip"
            collapsed="collapsed"
            @click.native="goToSlide(index)"
          ></tip>
        </transition-group>
      </div>
    </transition>

    <div id="tipsFullscreen" :class="showTipModal ? 'show' : 'hide'">
      <span id="closeButton" v-on:click="closeTipModal()">
        <Icon name="close_grey" />
      </span>
      <splide
        :options="tipsSplideOptions"
        :slides="slidesInSlider"
        ref="tipsCarousel"
        id="wwfSlideTips"
      >
        <splide-slide
          v-for="(tip, index) in slidesInSlider.slice()"
          :key="'tip_' + index"
        >
          <div class="splide__slide__container">
            <tip :tip="tip" @click="goToNextSlide()" expanded="expanded"></tip>
          </div>
        </splide-slide>
      </splide>
    </div>
  </div>
</template>

<script>
import Tip from "@/components/Tip.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import Icon from "@/components/Icon.vue";
import Button from "@/components/Button.vue";
export default {
  props: {
    activeTipsLocal: Array,
    showSliders: Boolean,
    climateList: Array,
  },
  components: { Tip, Splide, SplideSlide, Icon, Button },
  data() {
    return {
      showTipModal: false,
      tipsSplideOptions: {
        rewind: true,
        perPage: 1,
        gap: "0",
        arrows: true,
        height: "100vh",
      },
      count: 0,
      currentIndex: 0,
    };
  },
  computed: {
    desktopModifier() {
      if (this.$store.state.isMobile) {
        return this.showSliders;
      }
      return false;
    },
    numberOfTips() {
      if (this.$store.state.isMobile) {
        return 3;
      } else {
        return 100;
      }
    },
    tipsInOrder() {
      return this.activeTipsLocal
        .filter(function (item) {
          return item.weight > 0;
        })
        .sort((b, a) => (a.weight > b.weight ? 1 : -1))
        .slice(0, this.numberOfTips);
    },
    slidesInSlider() {
      if (this.desktopModifier) {
        // console.log("show tips from local", this.activeTipsLocal);
        return this.activeTipsLocal;
      } else {
        // console.log("show tips in order", this.tipsInOrder);
        return this.tipsInOrder;
      }
    },
  },
  methods: {
    // onSplideMove(splide) {
    //   console.log("on splide move", splide);
    //   if (splide.index != this.currentIndex) {
    //     //gsap.to(window, { duration: 1, scrollTo: 0, ease: "Strong.easeOut" });
    //     this.currentIndex = splide.index;
    //   }
    //   // do something
    // },

    goToSlide(index) {
      this.showTipModal = true;
      // console.log("go to slide", index);
      this.$refs.tipsCarousel.go(index);
      this.$store.state.noScroll = true;
    },
    goToNextSlide() {
      this.$refs.tipsCarousel.go("+1");
      // console.log("go to next slide");
    },
    closeTipModal() {
      this.showTipModal = false;
      //this.resetTipModal();
      this.$store.state.noScroll = false;
    },
    resetTipModal() {
      setTimeout(
        () => this.$refs.tipsCarousel.go(this.slidesInSlider.length - 1),
        350
      );
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "../assets/css/colors.scss";
@import "../assets/css/mixins.scss";

h2.bm {
  color: $mangfald;
}

#wwfSlideTips .splide__pagination {
  bottom: 40px;

  li .is-active {
    background: #808080;
  }
}

#tips-result {
  background-color: $bioGreen;

  .tip {
    margin-bottom: 10px;
  }
}

#tips {
  position: fixed;
  bottom: calc(100% - 350px + 90px + 15px);

  @include for-small-height() {
    bottom: calc(100% - 250px + 80px + 15px);
  }

  z-index: 10;
  width: 100%;

  &.collapsed {
    // $i: 0;
    // @while $i < 10 {
    //   $i: $i + 1;
    // }

    .tip {
      transition: all 250ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */
      position: absolute;
      width: 95%;
      left: 2.5%;
      cursor: pointer;
      // opacity: 0.7;
      opacity: 1;
    }

    //for more than 3 tips
    .tip:nth-last-child(1) {
      width: calc(95% - 40px);
    }
    .tip:nth-last-child(2) {
      width: calc(95% - 20px);
      // opacity: 0.7;
      opacity: 1;
    }

    //for only 1 tip
    &[data-numTips="1"] {
      .tip:nth-last-child(1) {
        width: calc(95%);
        opacity: 1;
      }
    }

    //for only 2 tips
    &[data-numTips="2"] {
      .tip:nth-last-child(1) {
        width: calc(95% - 20px);
        opacity: 1;
      }
      .tip:nth-last-child(2) {
        width: calc(95%);
        // opacity: 0.7;
        opacity: 1;
      }
    }

    //for only 3 tips
    &[data-numTips="3"] {
      .tip:nth-last-child(1) {
        width: calc(95% - 40px);
        opacity: 1;
      }
      .tip:nth-last-child(2) {
        width: calc(95% - 20px);
        // opacity: 0.7;
        opacity: 1;
      }
    }
  }
}

/* TRANSITIONS */
//tip entry

.tip-slide {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); /* ease-out */
}

.tip-slide-enter-active {
  animation: tip-slide-in 0.85s;
}
.tip-slide-leave-active {
  animation: tip-slide-in 0.25s reverse;
}
@keyframes tip-slide-in {
  0% {
    transform: translateX(-100vh);
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    transform: translateX(0);
  }
}

//tips-wrapper
.tips-wrapper-slide {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); /* ease-out */
}

.tips-wrapper-slide-enter-active {
  animation: tips-wrapper-slide-in 0.95s;
}
.tips-wrapper-slide-leave-active {
  animation: tips-wrapper-slide-out 1.25s;
  transition-timing-function: cubic-bezier(1, 0.58, 0, 0); /* ease-out */
}
@keyframes tips-wrapper-slide-in {
  0% {
    transform: translateX(-100vh);
    opacity: 0;
  }

  50% {
    transform: translateX(-100vh);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes tips-wrapper-slide-out {
  100% {
    transform: translateX(-100vh);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  30% {
    transform: translateX(0);
    opacity: 1;
  }

  0% {
    transform: translateX(0);
    opacity: 1;
  }
}

#tipsFullscreen {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;

  transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94); /* easeOutQuad */

  #closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 10;

    @include for-tablet-portrait-up() {
      right: calc(50% - 300px);
      transform: translateX(-50%);
    }
  }

  .splide {
    width: 100vw;
    min-height: 100%;
  }

  &.show {
    animation-name: fullscreenTipsShow;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
    z-index: 60;
  }
  &.hide {
    overflow: hidden;
    height: 0px;
    min-height: 0;
    opacity: 0;
    z-index: 0;
  }

  @keyframes fullscreenTipsShow {
    0% {
      opacity: 0;
      min-height: 0;
      height: 0;
    }
    5% {
      opacity: 0;
      min-height: 0vh;
      height: 100%;
      transform: translateY(15vh);
    }
    100% {
      opacity: 1;
      min-height: 100vh;
      transform: translateY(0);
      height: auto;
    }
  }
}

.splide__slide__container {
  overflow-y: auto;

  .button-wrapper {
    position: absolute;
    bottom: 95px;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    width: 100%;
  }
}

/*SHAKER*/
@keyframes wiggle {
  2% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  4% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  6% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  8% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  10% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  12% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  14% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  16% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  18% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  20% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
</style>
