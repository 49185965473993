import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Difference from "../views/Difference.vue";
import Hallbarhetsaspekter from "../views/Hallbarhetsaspekter.vue";
import Klimatbudget from "../views/Klimatbudget.vue";
import Fragor from "../views/Fragor.vue";
import VueSocialSharing from "vue-social-sharing";

Vue.use(VueRouter);
Vue.use(VueSocialSharing);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/matkalkylatorn",
    name: "About",
    component: About,
  },
  {
    path: "/make-a-difference",
    name: "Difference",
    component: Difference,
  },
  {
    path: "/sustainability",
    name: "Övriga hållbarhetsaspekter",
    component: Hallbarhetsaspekter,
  },
  {
    path: "/climate-budget",
    name: "Din klimatbudget",
    component: Klimatbudget,
  },
  {
    path: "/questions",
    name: "Vanliga frågor",
    component: Fragor,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
